module.exports = {
    TXSHIELD_SECURE_BASE_URL: "",
    API: "https://gateway.cliq.com/api/v2/index.php",
    API_TX: "https://gateway.cliq.com/api/v3/txprocess",
    API_3DS_TX: "https://gateway.cliq.com/api/v3/3ds",
    API_TXALERT: "https://gateway.cliq.com/api/v3/txalerts",
    API_TXFRAUD: "https://gateway.cliq.com/api/v3/txfraud",
    API_GENERAL: "",
    API_MERCHANT: "https://gateway.cliq.com/api/v3/merchant",
    API_RETAILER: "https://gateway.cliq.com/api/v3/retailer",
    API_SITE: "https://gateway.cliq.com/api/v3/site",
    API_DATA_SOURCE: "https://gateway.cliq.com/api/v3/datasource",
    API_BASE_URL: "https://gateway.cliq.com/api",
    SOCKET_URL: "",
    OI_URL: "https://txfind.payshield.ai",
    OI_API_ACCESS_TOKEN: "53616c7465645f5fb6bbccb5569a1af0494be7cdd40750f18609284820ad8a5c890f8e280bd9eb891e9c307516711ef247a0aa3a8c2b71016632fa5f3722b413",
    CBM_URL: "https://uat-txcbm.payshield.ai",
    CBM_TOKEN: "53616c7465645f5fc28ebed9db4c570a35d39ab45a966985c179b4d8c4ab9c1011f89108cda6471b41b9b1e4cfaf751ee1770663ea71da24694d8dec9a3130ab7f9c6547d25b678898d04b342c969c87e90fdc1de5902fd943fd9011383e8e100874b4605ac3f3089b72aaa33e0a6edbdb10b2194751de06b8cb0bd5bf3b29b08dcae605b63e7464b15c84f90099502d46984d267a563581ee1df467a321d9ba4157ac917ccbe72ad41d02c5eb984049dbec30d9d9d8e2b623898aea9e5f4c802716771c82b89d441c3ef03dd0959caa8c81025d293104f96a134596ba6f1e7ab253da851bcef33303539db2923f1f03",
    THREEDS_URL: "",
    THREEDS_TOKEN: "",
    REALTIME_DASHBOARD: false,
    THEME: "cliq",
    SKIP_2FA: false,
    DEV_MODE: false,
    SESSION_TIMEOUT: 15,
    DISABLE_OI: true,
    DISABLE_CBM: true,
    DISABLE_ALERTS: false,
    DISABLE_3DS: true,
    DISABLE_THREEDS_MICROSERVICE: true,
    DISABLE_PAYMENTS: false,
    DISABLE_MATCH_REFUND: false,
    WHITELABEL_DISPLAY_NAME: "Cliq",
    API_TXALERT2:"https://uat-txalert.payshield.ai/api/v1",
    TXALERT2_TOKEN:"53616c7465645f5f88b96e2f403399d3a4ab46ec7a7a73e1f0baf845f63bf7c6baa2dcdb3f76643df305c5aca1f2bc2c1c66bfe05507bfd43de459b47de455322a5ae8a8bf9fb568b36b3ad77f677150375296ef1b0c1b187cc07df01a9ef39c1c340bcdd5302c3f81d83ef9cef5407b3a1df273150fc23d321c74b7e9fb6b14",
    DISABLE_TXALERT2: true,
    TOKEN_ENCODING_ENABLED: "true",
    DISPLAY_PAYMENT_LINK: false,
}
